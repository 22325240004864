module.exports = {
  customer: 'southglos',
  environment: 'production',
  isBetterCare: true,
  yourMoney: true,
  jobsVolunteering: false,
  googleAnalyticsId: 'G-G6X8ET1Y16',
  robots: false,
  chatWidget: false,
  entitledToBaseUrl: 'https://southglosbettercare.entitledto.co.uk',
  entitledToReassessUrl: 'https://southglosbettercare.entitledto.co.uk/reassessbetteroff',
  requirePostcode: false,

  app: {
    home: 'https://southglos.bettercare.org.uk'
  },
  serverBaseUrl: 'https://api-southglos.bettercare.org.uk',
  api: {
    baseUrl: 'https://api-southglos.bettercare.org.uk/internal/v1'
  },
  authGateway: 'https://account.southglos.bettercare.org.uk',
  loginServices: [
    { name: 'azuread' }
  ],
  ui: {
    showJobSearchJournal: false,
    showOfflineForms: true,
    showAssessmentMutators: false,
    restrictions: {
      applications: false
    }
  },
  airbrake: {
    enabled: true,
    projectId: 131082,
    projectKey: 'facc9a060872b357453b677f15d319e3'
  }
};
